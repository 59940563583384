<template>
  <div ref="store_viewer_wrapper" v-loading="is_pending" class="store-viewer-wrapper slide-in-left">
    <div ref="store_viewer_edit" class="store-viewer-edit">
      <div class="store-viewer-edit-header">
        <p>{{marked_path}}</p>
      </div>
      <div class="store-viewer-edit-content"> 
          <el-radio-group style="width:50%" v-if="typeof edit_value == 'boolean'" v-model="edit_value">
            <el-radio :label="false">false</el-radio>
            <el-radio :label="true">true</el-radio>
          </el-radio-group>
          <el-input v-else v-model="edit_value" style="width:50%" :placeholder="marked_path" />
          <el-button @click="handle_update_edit" style="margin-left:5px;" type="success">update</el-button>
          <el-button @click="handle_cancel_edit" type="danger">cancel</el-button>
      </div>
    </div>
    <div class="store-viewer-wrapper-header">
      <h2>Store Viewer</h2>
      <div @click="handle_close_model" class="exit-btn">
        <i class="material-symbols-rounded ">chevron_left</i>
      </div>
    </div>
    <div class="store-viewer-wrapper-content">
      <el-tree  ref="treeRef" show-checkbox :data="data" node-key="id" :props="defaultProps" @node-click="handle_node_click" :check-on-click-node="true"/>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../store'
import { computed } from '@vue/runtime-core'

export default {
    emits:['close_model'],
    setup(_,context){
      const store_viewer_wrapper = ref()
      const is_pending = ref(false)
      const defaultProps = {
        children: 'children',
        label: 'label',
        disabled: 'disabled',
      }
      const store_viewer_edit = ref()
      const marked_path = ref('')
      const marked_path_arr = ref([])
      const edit_value = ref(null)

      const store_states = ref(computed(()=>{
        return store.state
      }))

      const treeRef = ref()
      const data = ref([])
      
      const chenged_value_in_store_by_user = ref('')

      const handle_close_model = () => {
        store_viewer_wrapper.value.classList.add('slide-out-left')
        setTimeout(() => {
          context.emit('close_model')
        }, 500);
      }

      const handle_cancel_edit = () => {
        marked_path.value = ''
        store_viewer_edit.value.classList.remove('show-edit')
        treeRef.value.setCheckedKeys([], false)
      }

      const handle_update_edit = () => {
        if(edit_value.value!=null){
          setToValue(store.state,edit_value.value,marked_path.value)
        }
      }

      async function setToValue(obj,value,path) {
        let parent = obj;
        let arr = path.split('/')
        arr.pop()
        for (let i = 0; i < arr.length-1; i++){
          parent = parent[arr[i]];
        }
        parent[arr[arr.length-1]] = value

        await init()
        store_viewer_edit.value.classList.remove('show-edit')
        treeRef.value.setCheckedKeys([], false)
      }

      const handle_node_click = (data) => {
        if(!data.children){
          
          const marked = [
            ...treeRef.value.getHalfCheckedKeys(false),
            ...treeRef.value.getCheckedKeys(false)
          ]

          if(marked.length>0){
            marked_path_arr.value = marked
            marked_path.value = marked.join('/')
            edit_value.value = marked[marked.length-1]
            store_viewer_edit.value.classList.toggle('show-edit')
          }else{
            store_viewer_edit.value.classList.remove('show-edit')
          }
        }
      }

      const sleep = ()=>{
        return new Promise((resolve)=>setTimeout(resolve,50))
      }

      const organized_nested_obj = async(obj, result=[]) => {  
            const r = result
            if(Array.isArray(obj)){
              for(let i=0; i < obj.length; i++){
                if(typeof obj[i] != 'object'){
                  r.push({
                    id:i,
                    label:i,
                    disabled: true,
                    children: [
                      {
                        label: obj[i],
                      },
                    ],
                  })
                }
                else if(typeof obj[i] == 'object'){
                    await sleep()
                    r.push({
                      id:i,
                      label:i,
                      disabled: true,
                      children: await organized_nested_obj(obj[i])
                    })
                }
              }
            }
            else{
              for (const [key, value] of Object.entries(obj)) {
                if(typeof value != 'object' || typeof value == 'function' || value==null){
                  r.push({
                    id:key,
                    label:key,
                    disabled: true,
                    children: [
                      {
                        id:value,
                        label: JSON.stringify(value),
                      },
                    ],
                  })
                }
                else{
                    await sleep()
                    r.push({
                      id: key,
                      label:key,
                      disabled: true,
                      children: await organized_nested_obj(value)
                    })
                }
              }
            }
            return r
      }


      const start = async() => {
        return new Promise(async(resolve)=>{
          const res = await organized_nested_obj(store_states.value)
          resolve(res)
        })
      }

      const init = async() => {
        is_pending.value = true
        data.value = await start()
        is_pending.value = false
      }
      

      init()

      return{
        handle_node_click,
        handle_cancel_edit,
        handle_update_edit,
        handle_close_model,
        store_viewer_wrapper,
        store_states,
        data,
        defaultProps,
        treeRef,
        chenged_value_in_store_by_user,
        store_viewer_edit,
        marked_path,
        edit_value,
        marked_path_arr,
        is_pending,
      }
    }
}
</script>

<style scoped>

  .store-viewer-wrapper{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 300px;
    height: 70%;
    background: #fff;
    padding: 0 5px;
    border-radius: 10px 10px 0 0;
  }
  .store-viewer-wrapper-header{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .store-viewer-wrapper-content{
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    overflow-y: auto;
  }
  .store-viewer-edit{
    background-color: whitesmoke;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 50px);
    height: 50px;
    transform: translateY(0);
    opacity: 0;
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    z-index: 12;
  }
  .store-viewer-edit.show-edit{
    opacity: 1;
    transform: translateY(-75px);
    width: 100%;
    height: 70px;
  }
  .store-viewer-edit-header{
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  .store-viewer-edit-content{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  .exit-btn{
    transform: translateY(-40px);
    right: 0;
    left: unset;
  }
  .exit-btn i{
    font-size: 25px;
  }

</style>